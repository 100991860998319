import { Component, createApp } from 'vue';
import { createAuth0 } from "@auth0/auth0-vue";
import { Router } from 'vue-router';
import { VueQueryPlugin } from 'vue-query';
import VueAwesomePaginate from 'vue-awesome-paginate';
import { createHead } from '@vueuse/head'

import { AUTH0_CLIENTID, AUTH0_DOMAIN } from "@/configs";
import { pinia } from '@/stores';

export const setupEntryPoint = (rootComponent: Component, router: Router) => {
  const app = createApp(rootComponent);
  const head = createHead()
  
  app.use(
    createAuth0({
      domain: AUTH0_DOMAIN,
      clientId: AUTH0_CLIENTID,
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    })
  );

  app.use(router);
  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(VueAwesomePaginate);
  app.use(head);

  app.mount('#app');
};
